// source: query/account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.query.AccountBalance', null, global);
goog.exportSymbol('proto.query.AccountListEntry', null, global);
goog.exportSymbol('proto.query.AccountOverview', null, global);
goog.exportSymbol('proto.query.CumulatedAccountTransaction', null, global);
goog.exportSymbol('proto.query.FetchAccountDetailsQueryRequest', null, global);
goog.exportSymbol('proto.query.FetchAccountDetailsQueryResponse', null, global);
goog.exportSymbol('proto.query.FetchAccountListQueryRequest', null, global);
goog.exportSymbol('proto.query.FetchAccountListQueryResponse', null, global);
goog.exportSymbol('proto.query.FetchAccountsOverviewQueryRequest', null, global);
goog.exportSymbol('proto.query.FetchAccountsOverviewQueryResponse', null, global);
goog.exportSymbol('proto.query.FetchedAccountDetailsLevel', null, global);
goog.exportSymbol('proto.query.SummarizedAccountTransaction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.FetchAccountsOverviewQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.query.FetchAccountsOverviewQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.FetchAccountsOverviewQueryRequest.displayName = 'proto.query.FetchAccountsOverviewQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.FetchAccountsOverviewQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.query.FetchAccountsOverviewQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.FetchAccountsOverviewQueryResponse.displayName = 'proto.query.FetchAccountsOverviewQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.AccountOverview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.query.AccountOverview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.AccountOverview.displayName = 'proto.query.AccountOverview';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.CumulatedAccountTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.query.CumulatedAccountTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.CumulatedAccountTransaction.displayName = 'proto.query.CumulatedAccountTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.AccountBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.query.AccountBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.AccountBalance.displayName = 'proto.query.AccountBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.SummarizedAccountTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.query.SummarizedAccountTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.SummarizedAccountTransaction.displayName = 'proto.query.SummarizedAccountTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.FetchAccountDetailsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.query.FetchAccountDetailsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.FetchAccountDetailsQueryRequest.displayName = 'proto.query.FetchAccountDetailsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.FetchAccountDetailsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.query.FetchAccountDetailsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.query.FetchAccountDetailsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.FetchAccountDetailsQueryResponse.displayName = 'proto.query.FetchAccountDetailsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.AccountListEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.query.AccountListEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.AccountListEntry.displayName = 'proto.query.AccountListEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.FetchAccountListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.query.FetchAccountListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.FetchAccountListQueryRequest.displayName = 'proto.query.FetchAccountListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.query.FetchAccountListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.query.FetchAccountListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.query.FetchAccountListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.query.FetchAccountListQueryResponse.displayName = 'proto.query.FetchAccountListQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.FetchAccountsOverviewQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.query.FetchAccountsOverviewQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.FetchAccountsOverviewQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountsOverviewQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.FetchAccountsOverviewQueryRequest}
 */
proto.query.FetchAccountsOverviewQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.FetchAccountsOverviewQueryRequest;
  return proto.query.FetchAccountsOverviewQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.FetchAccountsOverviewQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.FetchAccountsOverviewQueryRequest}
 */
proto.query.FetchAccountsOverviewQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.FetchAccountsOverviewQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.FetchAccountsOverviewQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.FetchAccountsOverviewQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountsOverviewQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.FetchAccountsOverviewQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.query.FetchAccountsOverviewQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.FetchAccountsOverviewQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountsOverviewQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountsMap: (f = msg.getAccountsMap()) ? f.toObject(includeInstance, proto.query.AccountOverview.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.FetchAccountsOverviewQueryResponse}
 */
proto.query.FetchAccountsOverviewQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.FetchAccountsOverviewQueryResponse;
  return proto.query.FetchAccountsOverviewQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.FetchAccountsOverviewQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.FetchAccountsOverviewQueryResponse}
 */
proto.query.FetchAccountsOverviewQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotal(value);
      break;
    case 2:
      var value = msg.getAccountsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.query.AccountOverview.deserializeBinaryFromReader, "", new proto.query.AccountOverview());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.FetchAccountsOverviewQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.FetchAccountsOverviewQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.FetchAccountsOverviewQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountsOverviewQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAccountsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.query.AccountOverview.serializeBinaryToWriter);
  }
};


/**
 * optional uint64 total = 1;
 * @return {number}
 */
proto.query.FetchAccountsOverviewQueryResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.query.FetchAccountsOverviewQueryResponse} returns this
 */
proto.query.FetchAccountsOverviewQueryResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<string, AccountOverview> accounts = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.query.AccountOverview>}
 */
proto.query.FetchAccountsOverviewQueryResponse.prototype.getAccountsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.query.AccountOverview>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.query.AccountOverview));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.query.FetchAccountsOverviewQueryResponse} returns this
 */
proto.query.FetchAccountsOverviewQueryResponse.prototype.clearAccountsMap = function() {
  this.getAccountsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.AccountOverview.prototype.toObject = function(opt_includeInstance) {
  return proto.query.AccountOverview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.AccountOverview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.AccountOverview.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    balance: (f = msg.getBalance()) && proto.query.AccountBalance.toObject(includeInstance, f),
    totalexpenses: (f = msg.getTotalexpenses()) && proto.query.CumulatedAccountTransaction.toObject(includeInstance, f),
    totalincomes: (f = msg.getTotalincomes()) && proto.query.CumulatedAccountTransaction.toObject(includeInstance, f),
    totalsenttransfers: (f = msg.getTotalsenttransfers()) && proto.query.CumulatedAccountTransaction.toObject(includeInstance, f),
    totalreceivedtransfers: (f = msg.getTotalreceivedtransfers()) && proto.query.CumulatedAccountTransaction.toObject(includeInstance, f),
    totalfutureexpenses: (f = msg.getTotalfutureexpenses()) && proto.query.CumulatedAccountTransaction.toObject(includeInstance, f),
    totalfutureincomes: (f = msg.getTotalfutureincomes()) && proto.query.CumulatedAccountTransaction.toObject(includeInstance, f),
    totalfuturetransfers: (f = msg.getTotalfuturetransfers()) && proto.query.CumulatedAccountTransaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.AccountOverview}
 */
proto.query.AccountOverview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.AccountOverview;
  return proto.query.AccountOverview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.AccountOverview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.AccountOverview}
 */
proto.query.AccountOverview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = new proto.query.AccountBalance;
      reader.readMessage(value,proto.query.AccountBalance.deserializeBinaryFromReader);
      msg.setBalance(value);
      break;
    case 5:
      var value = new proto.query.CumulatedAccountTransaction;
      reader.readMessage(value,proto.query.CumulatedAccountTransaction.deserializeBinaryFromReader);
      msg.setTotalexpenses(value);
      break;
    case 6:
      var value = new proto.query.CumulatedAccountTransaction;
      reader.readMessage(value,proto.query.CumulatedAccountTransaction.deserializeBinaryFromReader);
      msg.setTotalincomes(value);
      break;
    case 7:
      var value = new proto.query.CumulatedAccountTransaction;
      reader.readMessage(value,proto.query.CumulatedAccountTransaction.deserializeBinaryFromReader);
      msg.setTotalsenttransfers(value);
      break;
    case 8:
      var value = new proto.query.CumulatedAccountTransaction;
      reader.readMessage(value,proto.query.CumulatedAccountTransaction.deserializeBinaryFromReader);
      msg.setTotalreceivedtransfers(value);
      break;
    case 9:
      var value = new proto.query.CumulatedAccountTransaction;
      reader.readMessage(value,proto.query.CumulatedAccountTransaction.deserializeBinaryFromReader);
      msg.setTotalfutureexpenses(value);
      break;
    case 10:
      var value = new proto.query.CumulatedAccountTransaction;
      reader.readMessage(value,proto.query.CumulatedAccountTransaction.deserializeBinaryFromReader);
      msg.setTotalfutureincomes(value);
      break;
    case 11:
      var value = new proto.query.CumulatedAccountTransaction;
      reader.readMessage(value,proto.query.CumulatedAccountTransaction.deserializeBinaryFromReader);
      msg.setTotalfuturetransfers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.AccountOverview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.AccountOverview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.AccountOverview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.AccountOverview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBalance();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.query.AccountBalance.serializeBinaryToWriter
    );
  }
  f = message.getTotalexpenses();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.query.CumulatedAccountTransaction.serializeBinaryToWriter
    );
  }
  f = message.getTotalincomes();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.query.CumulatedAccountTransaction.serializeBinaryToWriter
    );
  }
  f = message.getTotalsenttransfers();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.query.CumulatedAccountTransaction.serializeBinaryToWriter
    );
  }
  f = message.getTotalreceivedtransfers();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.query.CumulatedAccountTransaction.serializeBinaryToWriter
    );
  }
  f = message.getTotalfutureexpenses();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.query.CumulatedAccountTransaction.serializeBinaryToWriter
    );
  }
  f = message.getTotalfutureincomes();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.query.CumulatedAccountTransaction.serializeBinaryToWriter
    );
  }
  f = message.getTotalfuturetransfers();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.query.CumulatedAccountTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp createdAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.query.AccountOverview.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.query.AccountOverview} returns this
*/
proto.query.AccountOverview.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.AccountOverview.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.query.AccountOverview.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AccountBalance balance = 4;
 * @return {?proto.query.AccountBalance}
 */
proto.query.AccountOverview.prototype.getBalance = function() {
  return /** @type{?proto.query.AccountBalance} */ (
    jspb.Message.getWrapperField(this, proto.query.AccountBalance, 4));
};


/**
 * @param {?proto.query.AccountBalance|undefined} value
 * @return {!proto.query.AccountOverview} returns this
*/
proto.query.AccountOverview.prototype.setBalance = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.clearBalance = function() {
  return this.setBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.AccountOverview.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CumulatedAccountTransaction totalExpenses = 5;
 * @return {?proto.query.CumulatedAccountTransaction}
 */
proto.query.AccountOverview.prototype.getTotalexpenses = function() {
  return /** @type{?proto.query.CumulatedAccountTransaction} */ (
    jspb.Message.getWrapperField(this, proto.query.CumulatedAccountTransaction, 5));
};


/**
 * @param {?proto.query.CumulatedAccountTransaction|undefined} value
 * @return {!proto.query.AccountOverview} returns this
*/
proto.query.AccountOverview.prototype.setTotalexpenses = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.clearTotalexpenses = function() {
  return this.setTotalexpenses(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.AccountOverview.prototype.hasTotalexpenses = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CumulatedAccountTransaction totalIncomes = 6;
 * @return {?proto.query.CumulatedAccountTransaction}
 */
proto.query.AccountOverview.prototype.getTotalincomes = function() {
  return /** @type{?proto.query.CumulatedAccountTransaction} */ (
    jspb.Message.getWrapperField(this, proto.query.CumulatedAccountTransaction, 6));
};


/**
 * @param {?proto.query.CumulatedAccountTransaction|undefined} value
 * @return {!proto.query.AccountOverview} returns this
*/
proto.query.AccountOverview.prototype.setTotalincomes = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.clearTotalincomes = function() {
  return this.setTotalincomes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.AccountOverview.prototype.hasTotalincomes = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CumulatedAccountTransaction totalSentTransfers = 7;
 * @return {?proto.query.CumulatedAccountTransaction}
 */
proto.query.AccountOverview.prototype.getTotalsenttransfers = function() {
  return /** @type{?proto.query.CumulatedAccountTransaction} */ (
    jspb.Message.getWrapperField(this, proto.query.CumulatedAccountTransaction, 7));
};


/**
 * @param {?proto.query.CumulatedAccountTransaction|undefined} value
 * @return {!proto.query.AccountOverview} returns this
*/
proto.query.AccountOverview.prototype.setTotalsenttransfers = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.clearTotalsenttransfers = function() {
  return this.setTotalsenttransfers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.AccountOverview.prototype.hasTotalsenttransfers = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional CumulatedAccountTransaction totalReceivedTransfers = 8;
 * @return {?proto.query.CumulatedAccountTransaction}
 */
proto.query.AccountOverview.prototype.getTotalreceivedtransfers = function() {
  return /** @type{?proto.query.CumulatedAccountTransaction} */ (
    jspb.Message.getWrapperField(this, proto.query.CumulatedAccountTransaction, 8));
};


/**
 * @param {?proto.query.CumulatedAccountTransaction|undefined} value
 * @return {!proto.query.AccountOverview} returns this
*/
proto.query.AccountOverview.prototype.setTotalreceivedtransfers = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.clearTotalreceivedtransfers = function() {
  return this.setTotalreceivedtransfers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.AccountOverview.prototype.hasTotalreceivedtransfers = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional CumulatedAccountTransaction totalFutureExpenses = 9;
 * @return {?proto.query.CumulatedAccountTransaction}
 */
proto.query.AccountOverview.prototype.getTotalfutureexpenses = function() {
  return /** @type{?proto.query.CumulatedAccountTransaction} */ (
    jspb.Message.getWrapperField(this, proto.query.CumulatedAccountTransaction, 9));
};


/**
 * @param {?proto.query.CumulatedAccountTransaction|undefined} value
 * @return {!proto.query.AccountOverview} returns this
*/
proto.query.AccountOverview.prototype.setTotalfutureexpenses = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.clearTotalfutureexpenses = function() {
  return this.setTotalfutureexpenses(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.AccountOverview.prototype.hasTotalfutureexpenses = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional CumulatedAccountTransaction totalFutureIncomes = 10;
 * @return {?proto.query.CumulatedAccountTransaction}
 */
proto.query.AccountOverview.prototype.getTotalfutureincomes = function() {
  return /** @type{?proto.query.CumulatedAccountTransaction} */ (
    jspb.Message.getWrapperField(this, proto.query.CumulatedAccountTransaction, 10));
};


/**
 * @param {?proto.query.CumulatedAccountTransaction|undefined} value
 * @return {!proto.query.AccountOverview} returns this
*/
proto.query.AccountOverview.prototype.setTotalfutureincomes = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.clearTotalfutureincomes = function() {
  return this.setTotalfutureincomes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.AccountOverview.prototype.hasTotalfutureincomes = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional CumulatedAccountTransaction totalFutureTransfers = 11;
 * @return {?proto.query.CumulatedAccountTransaction}
 */
proto.query.AccountOverview.prototype.getTotalfuturetransfers = function() {
  return /** @type{?proto.query.CumulatedAccountTransaction} */ (
    jspb.Message.getWrapperField(this, proto.query.CumulatedAccountTransaction, 11));
};


/**
 * @param {?proto.query.CumulatedAccountTransaction|undefined} value
 * @return {!proto.query.AccountOverview} returns this
*/
proto.query.AccountOverview.prototype.setTotalfuturetransfers = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.AccountOverview} returns this
 */
proto.query.AccountOverview.prototype.clearTotalfuturetransfers = function() {
  return this.setTotalfuturetransfers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.AccountOverview.prototype.hasTotalfuturetransfers = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.CumulatedAccountTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.query.CumulatedAccountTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.CumulatedAccountTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.CumulatedAccountTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalsum: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.CumulatedAccountTransaction}
 */
proto.query.CumulatedAccountTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.CumulatedAccountTransaction;
  return proto.query.CumulatedAccountTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.CumulatedAccountTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.CumulatedAccountTransaction}
 */
proto.query.CumulatedAccountTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalsum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.CumulatedAccountTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.CumulatedAccountTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.CumulatedAccountTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.CumulatedAccountTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTotalsum();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 totalCount = 1;
 * @return {number}
 */
proto.query.CumulatedAccountTransaction.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.query.CumulatedAccountTransaction} returns this
 */
proto.query.CumulatedAccountTransaction.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 totalSum = 2;
 * @return {number}
 */
proto.query.CumulatedAccountTransaction.prototype.getTotalsum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.query.CumulatedAccountTransaction} returns this
 */
proto.query.CumulatedAccountTransaction.prototype.setTotalsum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.AccountBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.query.AccountBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.AccountBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.AccountBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    negative: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.AccountBalance}
 */
proto.query.AccountBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.AccountBalance;
  return proto.query.AccountBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.AccountBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.AccountBalance}
 */
proto.query.AccountBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNegative(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.AccountBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.AccountBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.AccountBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.AccountBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNegative();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool negative = 1;
 * @return {boolean}
 */
proto.query.AccountBalance.prototype.getNegative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.query.AccountBalance} returns this
 */
proto.query.AccountBalance.prototype.setNegative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint64 amount = 2;
 * @return {number}
 */
proto.query.AccountBalance.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.query.AccountBalance} returns this
 */
proto.query.AccountBalance.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.query.AccountBalance.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.query.AccountBalance} returns this
 */
proto.query.AccountBalance.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.SummarizedAccountTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.query.SummarizedAccountTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.SummarizedAccountTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.SummarizedAccountTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    txcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    txamount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    purposetitle: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.SummarizedAccountTransaction}
 */
proto.query.SummarizedAccountTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.SummarizedAccountTransaction;
  return proto.query.SummarizedAccountTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.SummarizedAccountTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.SummarizedAccountTransaction}
 */
proto.query.SummarizedAccountTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTxcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTxamount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurposetitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.SummarizedAccountTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.SummarizedAccountTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.SummarizedAccountTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.SummarizedAccountTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxcount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTxamount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPurposetitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint64 txCount = 1;
 * @return {number}
 */
proto.query.SummarizedAccountTransaction.prototype.getTxcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.query.SummarizedAccountTransaction} returns this
 */
proto.query.SummarizedAccountTransaction.prototype.setTxcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 txAmount = 2;
 * @return {number}
 */
proto.query.SummarizedAccountTransaction.prototype.getTxamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.query.SummarizedAccountTransaction} returns this
 */
proto.query.SummarizedAccountTransaction.prototype.setTxamount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.query.SummarizedAccountTransaction.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.query.SummarizedAccountTransaction} returns this
 */
proto.query.SummarizedAccountTransaction.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string purposeTitle = 4;
 * @return {string}
 */
proto.query.SummarizedAccountTransaction.prototype.getPurposetitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.query.SummarizedAccountTransaction} returns this
 */
proto.query.SummarizedAccountTransaction.prototype.setPurposetitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.FetchAccountDetailsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.query.FetchAccountDetailsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.FetchAccountDetailsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountDetailsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    detailslevel: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.FetchAccountDetailsQueryRequest}
 */
proto.query.FetchAccountDetailsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.FetchAccountDetailsQueryRequest;
  return proto.query.FetchAccountDetailsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.FetchAccountDetailsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.FetchAccountDetailsQueryRequest}
 */
proto.query.FetchAccountDetailsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = /** @type {!proto.query.FetchedAccountDetailsLevel} */ (reader.readEnum());
      msg.setDetailslevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.FetchAccountDetailsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.FetchAccountDetailsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.FetchAccountDetailsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountDetailsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDetailslevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string accountId = 1;
 * @return {string}
 */
proto.query.FetchAccountDetailsQueryRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.query.FetchAccountDetailsQueryRequest} returns this
 */
proto.query.FetchAccountDetailsQueryRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FetchedAccountDetailsLevel detailsLevel = 2;
 * @return {!proto.query.FetchedAccountDetailsLevel}
 */
proto.query.FetchAccountDetailsQueryRequest.prototype.getDetailslevel = function() {
  return /** @type {!proto.query.FetchedAccountDetailsLevel} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.query.FetchedAccountDetailsLevel} value
 * @return {!proto.query.FetchAccountDetailsQueryRequest} returns this
 */
proto.query.FetchAccountDetailsQueryRequest.prototype.setDetailslevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.query.FetchAccountDetailsQueryResponse.repeatedFields_ = [5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.query.FetchAccountDetailsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.FetchAccountDetailsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountDetailsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    balance: (f = msg.getBalance()) && proto.query.AccountBalance.toObject(includeInstance, f),
    summarizedonetimeexpensesList: jspb.Message.toObjectList(msg.getSummarizedonetimeexpensesList(),
    proto.query.SummarizedAccountTransaction.toObject, includeInstance),
    summarizedonetimeincomesList: jspb.Message.toObjectList(msg.getSummarizedonetimeincomesList(),
    proto.query.SummarizedAccountTransaction.toObject, includeInstance),
    summarizedsenttransfersList: jspb.Message.toObjectList(msg.getSummarizedsenttransfersList(),
    proto.query.SummarizedAccountTransaction.toObject, includeInstance),
    summarizedreceivedtransfersList: jspb.Message.toObjectList(msg.getSummarizedreceivedtransfersList(),
    proto.query.SummarizedAccountTransaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.FetchAccountDetailsQueryResponse}
 */
proto.query.FetchAccountDetailsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.FetchAccountDetailsQueryResponse;
  return proto.query.FetchAccountDetailsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.FetchAccountDetailsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.FetchAccountDetailsQueryResponse}
 */
proto.query.FetchAccountDetailsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = new proto.query.AccountBalance;
      reader.readMessage(value,proto.query.AccountBalance.deserializeBinaryFromReader);
      msg.setBalance(value);
      break;
    case 5:
      var value = new proto.query.SummarizedAccountTransaction;
      reader.readMessage(value,proto.query.SummarizedAccountTransaction.deserializeBinaryFromReader);
      msg.addSummarizedonetimeexpenses(value);
      break;
    case 6:
      var value = new proto.query.SummarizedAccountTransaction;
      reader.readMessage(value,proto.query.SummarizedAccountTransaction.deserializeBinaryFromReader);
      msg.addSummarizedonetimeincomes(value);
      break;
    case 7:
      var value = new proto.query.SummarizedAccountTransaction;
      reader.readMessage(value,proto.query.SummarizedAccountTransaction.deserializeBinaryFromReader);
      msg.addSummarizedsenttransfers(value);
      break;
    case 8:
      var value = new proto.query.SummarizedAccountTransaction;
      reader.readMessage(value,proto.query.SummarizedAccountTransaction.deserializeBinaryFromReader);
      msg.addSummarizedreceivedtransfers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.FetchAccountDetailsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.FetchAccountDetailsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountDetailsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBalance();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.query.AccountBalance.serializeBinaryToWriter
    );
  }
  f = message.getSummarizedonetimeexpensesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.query.SummarizedAccountTransaction.serializeBinaryToWriter
    );
  }
  f = message.getSummarizedonetimeincomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.query.SummarizedAccountTransaction.serializeBinaryToWriter
    );
  }
  f = message.getSummarizedsenttransfersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.query.SummarizedAccountTransaction.serializeBinaryToWriter
    );
  }
  f = message.getSummarizedreceivedtransfersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.query.SummarizedAccountTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
*/
proto.query.FetchAccountDetailsQueryResponse.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AccountBalance balance = 4;
 * @return {?proto.query.AccountBalance}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.getBalance = function() {
  return /** @type{?proto.query.AccountBalance} */ (
    jspb.Message.getWrapperField(this, proto.query.AccountBalance, 4));
};


/**
 * @param {?proto.query.AccountBalance|undefined} value
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
*/
proto.query.FetchAccountDetailsQueryResponse.prototype.setBalance = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.clearBalance = function() {
  return this.setBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated SummarizedAccountTransaction summarizedOneTimeExpenses = 5;
 * @return {!Array<!proto.query.SummarizedAccountTransaction>}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.getSummarizedonetimeexpensesList = function() {
  return /** @type{!Array<!proto.query.SummarizedAccountTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.query.SummarizedAccountTransaction, 5));
};


/**
 * @param {!Array<!proto.query.SummarizedAccountTransaction>} value
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
*/
proto.query.FetchAccountDetailsQueryResponse.prototype.setSummarizedonetimeexpensesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.query.SummarizedAccountTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.query.SummarizedAccountTransaction}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.addSummarizedonetimeexpenses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.query.SummarizedAccountTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.clearSummarizedonetimeexpensesList = function() {
  return this.setSummarizedonetimeexpensesList([]);
};


/**
 * repeated SummarizedAccountTransaction summarizedOneTimeIncomes = 6;
 * @return {!Array<!proto.query.SummarizedAccountTransaction>}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.getSummarizedonetimeincomesList = function() {
  return /** @type{!Array<!proto.query.SummarizedAccountTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.query.SummarizedAccountTransaction, 6));
};


/**
 * @param {!Array<!proto.query.SummarizedAccountTransaction>} value
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
*/
proto.query.FetchAccountDetailsQueryResponse.prototype.setSummarizedonetimeincomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.query.SummarizedAccountTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.query.SummarizedAccountTransaction}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.addSummarizedonetimeincomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.query.SummarizedAccountTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.clearSummarizedonetimeincomesList = function() {
  return this.setSummarizedonetimeincomesList([]);
};


/**
 * repeated SummarizedAccountTransaction summarizedSentTransfers = 7;
 * @return {!Array<!proto.query.SummarizedAccountTransaction>}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.getSummarizedsenttransfersList = function() {
  return /** @type{!Array<!proto.query.SummarizedAccountTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.query.SummarizedAccountTransaction, 7));
};


/**
 * @param {!Array<!proto.query.SummarizedAccountTransaction>} value
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
*/
proto.query.FetchAccountDetailsQueryResponse.prototype.setSummarizedsenttransfersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.query.SummarizedAccountTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.query.SummarizedAccountTransaction}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.addSummarizedsenttransfers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.query.SummarizedAccountTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.clearSummarizedsenttransfersList = function() {
  return this.setSummarizedsenttransfersList([]);
};


/**
 * repeated SummarizedAccountTransaction summarizedReceivedTransfers = 8;
 * @return {!Array<!proto.query.SummarizedAccountTransaction>}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.getSummarizedreceivedtransfersList = function() {
  return /** @type{!Array<!proto.query.SummarizedAccountTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.query.SummarizedAccountTransaction, 8));
};


/**
 * @param {!Array<!proto.query.SummarizedAccountTransaction>} value
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
*/
proto.query.FetchAccountDetailsQueryResponse.prototype.setSummarizedreceivedtransfersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.query.SummarizedAccountTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.query.SummarizedAccountTransaction}
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.addSummarizedreceivedtransfers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.query.SummarizedAccountTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.query.FetchAccountDetailsQueryResponse} returns this
 */
proto.query.FetchAccountDetailsQueryResponse.prototype.clearSummarizedreceivedtransfersList = function() {
  return this.setSummarizedreceivedtransfersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.AccountListEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.query.AccountListEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.AccountListEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.AccountListEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.AccountListEntry}
 */
proto.query.AccountListEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.AccountListEntry;
  return proto.query.AccountListEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.AccountListEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.AccountListEntry}
 */
proto.query.AccountListEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.AccountListEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.AccountListEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.AccountListEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.AccountListEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.query.AccountListEntry.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.query.AccountListEntry} returns this
 */
proto.query.AccountListEntry.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.query.AccountListEntry.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.query.AccountListEntry} returns this
 */
proto.query.AccountListEntry.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.FetchAccountListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.query.FetchAccountListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.FetchAccountListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.FetchAccountListQueryRequest}
 */
proto.query.FetchAccountListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.FetchAccountListQueryRequest;
  return proto.query.FetchAccountListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.FetchAccountListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.FetchAccountListQueryRequest}
 */
proto.query.FetchAccountListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.FetchAccountListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.FetchAccountListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.FetchAccountListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.query.FetchAccountListQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.query.FetchAccountListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.query.FetchAccountListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.query.FetchAccountListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.query.AccountListEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.query.FetchAccountListQueryResponse}
 */
proto.query.FetchAccountListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.query.FetchAccountListQueryResponse;
  return proto.query.FetchAccountListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.query.FetchAccountListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.query.FetchAccountListQueryResponse}
 */
proto.query.FetchAccountListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new proto.query.AccountListEntry;
      reader.readMessage(value,proto.query.AccountListEntry.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.query.FetchAccountListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.query.FetchAccountListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.query.FetchAccountListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.query.FetchAccountListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.query.AccountListEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 total = 1;
 * @return {number}
 */
proto.query.FetchAccountListQueryResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.query.FetchAccountListQueryResponse} returns this
 */
proto.query.FetchAccountListQueryResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated AccountListEntry accounts = 2;
 * @return {!Array<!proto.query.AccountListEntry>}
 */
proto.query.FetchAccountListQueryResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.query.AccountListEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.query.AccountListEntry, 2));
};


/**
 * @param {!Array<!proto.query.AccountListEntry>} value
 * @return {!proto.query.FetchAccountListQueryResponse} returns this
*/
proto.query.FetchAccountListQueryResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.query.AccountListEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.query.AccountListEntry}
 */
proto.query.FetchAccountListQueryResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.query.AccountListEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.query.FetchAccountListQueryResponse} returns this
 */
proto.query.FetchAccountListQueryResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


/**
 * @enum {number}
 */
proto.query.FetchedAccountDetailsLevel = {
  FETCHED_ACCOUNT_DETAILS_LEVEL_MINIMAL: 0,
  FETCHED_ACCOUNT_DETAILS_LEVEL_FULL: 1
};

goog.object.extend(exports, proto.query);
